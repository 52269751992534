import React from 'react';
import styled from 'styled-components';
import Link from '../Link';

const Root = styled.footer`
  padding: 40px 0;
  color: ${(p) => p.theme.light};
  a {
    margin-right: 10px;
  }
`;

export default function Footer() {
  const year = new Date().getFullYear();
  return (
    <Root>
      &copy; {year}. Rewarp App.
      <br />
      <div style={{ marginTop: 10 }}>
        <Link to="/terms-and-conditions">Terms and Conditions</Link>{' '}
        <Link to="/privacy-policy">Privacy Policy</Link>
      </div>
    </Root>
  );
}
