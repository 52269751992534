import React from 'react';
import styled, { ThemeProvider } from 'styled-components';
import Navbar from '../Navbar';
import Footer from '../Footer';
import Container from '../Container';

import '../../styles/main.scss';

const Root = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  margin: auto;
`;
const Content = styled.main`
  flex-grow: 1;
  h1 {
    font-size: 28px;
    color: ${(p) => p.theme.dark};
  }
  p {
    margin-bottom: 15px;
    color: ${(p) => p.theme.light};
  }
  h2 {
    font-size: 18px;
    font-weight: 600;
    line-height: 1.6;
    margin-bottom: 10px;
    color: ${(p) => p.theme.dark};
  }
  ul {
    color: ${(p) => p.theme.light};
    list-style-type: circle;
    padding-left: 20px;
    line-height: 2;
    margin-bottom: 20px;
    li {
      padding-left: 10px;
    }
  }
`;

const theme = {
  primary: '#583cdf',
  dark: '#191923',
  light: '#898296',
  border: '#EAE9EC',
  // Breakpoints
  sm: `(max-width: 768px)`,
  md: `(min-width: 1024px)`,
  lg: `(min-width: 1200px)`,
};

export default function Layout({ children }) {
  return (
    <ThemeProvider theme={theme}>
      <Root className="rw-layout">
        <Container>
          <Navbar />
        </Container>
        <Content>{children}</Content>
        <Container>
          <Footer />
        </Container>
      </Root>
    </ThemeProvider>
  );
}
