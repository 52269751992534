import { Link } from 'gatsby';
import styled, { css } from 'styled-components';

export default styled(Link)`
  text-decoration: none;
  color: ${(p) => p.theme.primary};
  border-bottom: 1px solid ${(p) => p.theme.border};
  padding-bottom: 2px;
  &:hover {
    border-color: ${(p) => p.theme.primary};
  }
  ${(p) =>
    p.button &&
    css`
      padding: 15px 20px;
      background: ${() => p.theme.primary};
      color: #fff;
      border-radius: 5px;
      font-weight: bold;
    `};

  ${(p) =>
    p.button &&
    p.outline &&
    css`
      border: 1px solid ${() => p.theme.primary};
      background: transparent;
      color: ${() => p.theme.primary};
    `};
`;
