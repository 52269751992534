import React from 'react';
import styled from 'styled-components';
import Link from '../Link';

const Root = styled.nav`
  padding: 10px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media ${(p) => p.theme.md} {
    padding: 20px 0;
  } ;
`;

const Img = styled.img`
  margin-right: 8px;
  width: 24px;
`;

const NavLink = styled(Link)`
  margin-left: 20px;
  font-weight: 600;
  text-transform: uppercase;
  font-size: 13px;
  letter-spacing: 1px;
`;

const MainLink = styled(Link)`
  border: none;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 14px;
  letter-spacing: 1.5px;
  color: ${(p) => p.theme.primary};
`;

export default function Navbar() {
  return (
    <Root>
      <MainLink to="/">
        <Img src="/icon.png" />
        Rewarp
      </MainLink>
      <div>
        <NavLink to="/samples">Examples</NavLink>
        <NavLink to="/faqs">FAQs</NavLink>
      </div>
    </Root>
  );
}
